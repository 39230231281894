
import React from 'react';
import PostHero from '../components/PostHero'
import PostFooter from '../components/PostFooter'
import PostContent from '../components/PostContent'
import PostOutcome from '../components/PostOutcome'
import PostContentList from '../components/PostContentList'

import banner from '../images/fetch_pals/fetch_pals_banner.jpg';
import intro from '../images/fetch_pals/fetch_pals_post1.jpg';
import post1 from '../images/fetch_pals/fetch_pals_post2.jpg';
import post2 from '../images/fetch_pals/fetch_pals_post3.jpg';

import techStack from '../images/fetch_pals/fetch_pals_techStacks.jpg';



const PostFetchPals = () => {
    return (
        <div className="post_body">
        <div className="post_container">
            <PostHero 
            image={banner}
            title="Developing a web application to help users search for shelter dogs" 
                from="From" company="Personal Project"  role1="•  Buildng single page app uisng React.js + Vite" role2="• Retrieving data via API Calls" role3="• Creating filter and pagination logic"  role4="• Styling page using Bootstrap"
            />
            <PostContent classes="" image={post2} title="Project Objective" 
            body="
            This project is about creating a website that makes it easier for dog lovers to search for shelter dogs and give them a chance at a new home!
            " 
            />
            <PostContent classes="flex-row-reverse" image={intro} title="API Access Management" 
            body="
            To retrieve data using the API, user authentication was required through an email and name input. Once authenticated, the access token was stored in a cookie and used for subsequent API requests.            " 
            />

            <PostContentList classes="" image={post1} title="Search Page Requirements" 
            text={'• Filter results by breed. \n • Paginated Search results   \n • Results sorted alphabetically by breed \n • Sorting order to be either ascending or descending \n • Mark and save their favorite dogs' } />

           <PostOutcome image={techStack} classes="hidden" title="Future Enhancements" 
            body="
            This project has been a great opportunity for me to practice making API calls. In the future, I’d like to integrate the Google Maps API to display the locations of available dogs, making it easier for users to see where they are. Additionally, I plan to implement Firebase authentication to securely save users' search results and favorite selections, providing a more personalized experience.
            "
            button="Fetch Pals" url="https://jihyeonoh12.github.io/fetch_pals"
            button2="Github" url2="https://github.com/jihyeonoh12/fetch_pals"
                />
            <PostFooter />
            </div>
        </div>
    )
}



export default PostFetchPals